import { FC, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type IProps = {
  children: any;
  title?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  whiteHeader?: boolean;
  isOpen: boolean;
} & ({
  unclosable: true;
} | {
  unclosable?: false;
  onClose: () => void;
});

const Modal: FC<IProps> = (props) => {
  const setModalSizeClasses = () => {
    switch (props.size) {
      case 'sm':
        return 'lg:w-1/4 md:w-2/5 w-10/12';
      case 'md':
        return 'lg:w-1/3 md:w-3/5 w-10/12';
      case 'xl':
        return 'lg:w-3/4 w-10/12';
      case 'lg':
      default:
        return 'lg:w-1/2 md:w-4/5 w-10/12';
    }
  };

  const modalSize = setModalSizeClasses();

  const portal = (
    <Transition appear show={props.isOpen}>
      <Dialog as="div" className="relative z-[9200]" onClose={() => !props.unclosable && props.onClose()}>
        <div className="fixed inset-0 z-[9200] w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              className={modalSize}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={`rounded-lg bg-white w-full`}>
                <div
                  className={
                    props.whiteHeader
                      ? 'relative flex items-center justify-between p-4 border-b border-[#e9ecef]'
                      : 'relative flex items-center justify-between p-4 bg-primary'
                  }
                >
                  <Dialog.Title as="h4"
                                className={`text-center mb-0 leading-normal flex-grow ${props.whiteHeader ? 'text-black' : 'text-white'}`}>
                    {props.title}
                  </Dialog.Title>
                  { !props.unclosable && (
                    <button
                      className="absolute top-2 right-2 !cursor-pointer p-4 flex items-center"
                      type="button"
                      onClick={() => props.onClose()}
                    >
                      <i
                        className={
                          props.whiteHeader
                            ? 'text-black icon-cross'
                            : 'text-white icon-cross'
                        }
                      />
                    </button>
                  )}
                </div>
                <div className="p-4">
                  {props.children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        <div className="bg-black opacity-50 z-[9100] fixed inset-0"></div>
      </Dialog>
    </Transition>
  );

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? portal : null;
};

export default Modal;
